<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <SearchBar></SearchBar>
    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px;"></div>

    <!--Container - Popular Resorts -->
    <div class=" w-full mx-auto pt-0 bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex flex-wrap items-center justify-center">
          <div class="border-b pb-4 pt-8 mb-8 w-full">
            <div class="flex flex-col space-y-2 ">
              <div class="flex space-x-2 ">
                <h2 class="font-bold text-xl uppercase text-gray-600">
                  Special Offers
                </h2>

                <div
                  style="padding-top: 0.1em; padding-bottom: 0.1rem"
                  class="flex items-center text-sm px-3 bg-blue-200 text-blue-800 rounded-full"
                >
                  FILTER
                </div>
                <div
                  style="padding-top: 0.2em; padding-bottom: 0.2rem;"
                  class="flex items-center  space-x-1 text-sm px-2 bg-gray-200 text-gray-800 rounded-full"
                >
                  <div
                    style="width: 0.4rem; height: 0.4rem"
                    class="bg-gray-500 rounded-full"
                  ></div>
                  <div>EARLY BIRD</div>
                </div>
                <div
                  style="padding-top: 0.2em; padding-bottom: 0.2rem"
                  class="flex items-center space-x-1 text-sm px-2 bg-gray-200 text-gray-800 rounded-full"
                >
                  <div
                    style="width: 0.4rem; height: 0.4rem"
                    class="bg-gray-500 rounded-full"
                  ></div>
                  <div>30% OFF</div>
                </div>
                <div
                  style="padding-top: 0.2em; padding-bottom: 0.2rem"
                  class="flex items-center space-x-1 text-sm px-2 bg-gray-200 text-gray-800 rounded-full"
                >
                  <div
                    style="width: 0.4rem; height: 0.4rem"
                    class="bg-gray-500 rounded-full"
                  ></div>
                  <div>FREE EXCURSIONS</div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="property in properties"
            :key="property._id"
            class="w-full mb-4 sm:w-1/2 lg:w-1/3 py-6 px-3"
          >
            <div class="bg-white shadow-xl rounded-lg overflow-hidden">
              <div
                class="bg-cover bg-center h-56 p-4"
                :style="{
                  'background-image': `url(${$customConfig.api_host}/uploads/${property.featuredImage})`
                }"
              >
                <div class="flex justify-end">
                  <img src="/img/watermark.png" width="60px" />
                </div>
              </div>
              <div class="p-4">
                <p
                  class="uppercase tracking-wide text-sm font-bold text-gray-700"
                >
                  <i
                    v-for="n in property.starRating"
                    :key="n"
                    class="text-yellow-400 fas fa-star"
                  ></i>
                </p>
                <p class="text-2xl font-bold text-gray-900">
                  <router-link
                    :to="
                      '/accommodations/' +
                        property.name
                          .toLowerCase()
                          .split(' ')
                          .join('-')
                    "
                  >
                    {{ property.name }}
                  </router-link>
                </p>
                <p class="text-gray-700 text-sm">
                  {{ property.location }}
                </p>
              </div>

              <div class="px-4 pt-3 pb-4 border-t border-gray-300 bg-gray-100">
                <div
                  class="text-xs uppercase font-bold text-gray-600 tracking-wide"
                >
                  ROOM RATES START FROM
                  <span class="text-gray-900 text-sm font-bold">
                    USD 364 PP</span
                  >
                </div>
                <div class="flex items-center pt-2">
                  <div class="w-full justify-center">
                    <router-link
                      :to="
                        '/accommodations/' +
                          property.name
                            .toLowerCase()
                            .split(' ')
                            .join('-')
                      "
                      ><button
                        class="bg-gray-300 w-full justify-center hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                      >
                        <span>REQUEST QUOTE</span>
                      </button></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";
import SearchBar from "@/components/others/SearchBar";

export default {
  name: "OffersPage",
  data() {
    return {
      properties: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1
    };
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties`
        );
        this.properties = response.data.properties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    }
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    }
  },
  components: {
    HeaderLayout,
    FooterLayout,
    SearchBar
  }
};
</script>

<style scoped></style>
