var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('HeaderLayout'),_c('SearchBar'),_c('div',{staticStyle:{"clear":"both","display":"block","height":"0px"}}),_c('div',{staticClass:" w-full mx-auto pt-0 bg-white"},[_c('div',{staticClass:"max-w-7xl mx-auto px-4 sm:px-6"},[_c('div',{staticClass:"flex flex-wrap items-center justify-center"},[_vm._m(0),_vm._l((_vm.properties),function(property){return _c('div',{key:property._id,staticClass:"w-full mb-4 sm:w-1/2 lg:w-1/3 py-6 px-3"},[_c('div',{staticClass:"bg-white shadow-xl rounded-lg overflow-hidden"},[_c('div',{staticClass:"bg-cover bg-center h-56 p-4",style:({
                'background-image': ("url(" + (_vm.$customConfig.api_host) + "/uploads/" + (property.featuredImage) + ")")
              })},[_vm._m(1,true)]),_c('div',{staticClass:"p-4"},[_c('p',{staticClass:"uppercase tracking-wide text-sm font-bold text-gray-700"},_vm._l((property.starRating),function(n){return _c('i',{key:n,staticClass:"text-yellow-400 fas fa-star"})}),0),_c('p',{staticClass:"text-2xl font-bold text-gray-900"},[_c('router-link',{attrs:{"to":'/accommodations/' +
                      property.name
                        .toLowerCase()
                        .split(' ')
                        .join('-')}},[_vm._v(" "+_vm._s(property.name)+" ")])],1),_c('p',{staticClass:"text-gray-700 text-sm"},[_vm._v(" "+_vm._s(property.location)+" ")])]),_c('div',{staticClass:"px-4 pt-3 pb-4 border-t border-gray-300 bg-gray-100"},[_vm._m(2,true),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"w-full justify-center"},[_c('router-link',{attrs:{"to":'/accommodations/' +
                        property.name
                          .toLowerCase()
                          .split(' ')
                          .join('-')}},[_c('button',{staticClass:"bg-gray-300 w-full justify-center hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"},[_c('span',[_vm._v("REQUEST QUOTE")])])])],1)])])])])})],2)])]),_c('FooterLayout')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-b pb-4 pt-8 mb-8 w-full"},[_c('div',{staticClass:"flex flex-col space-y-2 "},[_c('div',{staticClass:"flex space-x-2 "},[_c('h2',{staticClass:"font-bold text-xl uppercase text-gray-600"},[_vm._v(" Special Offers ")]),_c('div',{staticClass:"flex items-center text-sm px-3 bg-blue-200 text-blue-800 rounded-full",staticStyle:{"padding-top":"0.1em","padding-bottom":"0.1rem"}},[_vm._v(" FILTER ")]),_c('div',{staticClass:"flex items-center  space-x-1 text-sm px-2 bg-gray-200 text-gray-800 rounded-full",staticStyle:{"padding-top":"0.2em","padding-bottom":"0.2rem"}},[_c('div',{staticClass:"bg-gray-500 rounded-full",staticStyle:{"width":"0.4rem","height":"0.4rem"}}),_c('div',[_vm._v("EARLY BIRD")])]),_c('div',{staticClass:"flex items-center space-x-1 text-sm px-2 bg-gray-200 text-gray-800 rounded-full",staticStyle:{"padding-top":"0.2em","padding-bottom":"0.2rem"}},[_c('div',{staticClass:"bg-gray-500 rounded-full",staticStyle:{"width":"0.4rem","height":"0.4rem"}}),_c('div',[_vm._v("30% OFF")])]),_c('div',{staticClass:"flex items-center space-x-1 text-sm px-2 bg-gray-200 text-gray-800 rounded-full",staticStyle:{"padding-top":"0.2em","padding-bottom":"0.2rem"}},[_c('div',{staticClass:"bg-gray-500 rounded-full",staticStyle:{"width":"0.4rem","height":"0.4rem"}}),_c('div',[_vm._v("FREE EXCURSIONS")])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-end"},[_c('img',{attrs:{"src":"/img/watermark.png","width":"60px"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs uppercase font-bold text-gray-600 tracking-wide"},[_vm._v(" ROOM RATES START FROM "),_c('span',{staticClass:"text-gray-900 text-sm font-bold"},[_vm._v(" USD 364 PP")])])}]

export { render, staticRenderFns }